import { Footer, Header } from '@/ui'

export const Layout = ({ children }) => {
	return (
		<>
			<Header />
			<main className="main">{children}</main>
			<Footer />
		</>
	)
}
