import { ProductService, StockService, WorkService } from '@/api'

import { Layout } from '@/layout/Main/Layout'

import { HomeScreen } from '@/screens'

import { IProduct, IStock, IWork } from '@/types'

const Home = ({
	product,
	work,
	stocks,
}: {
	product: IProduct[]
	work: IWork[]
	stocks: IStock[]
}) => (
	<Layout>
		<HomeScreen product={product} work={work} stocks={stocks} />
	</Layout>
)

export async function getServerSideProps({ res }) {
	res.setHeader(
		'Cache-Control',
		'public, s-maxage=3600, stale-while-revalidate=59'
	)

	try {
		const product = await ProductService.getAll()
		const work = await WorkService.getAll()
		const stocks = await StockService.getAll()

		return {
			props: {
				product: product?.sort((a, b) => a.name.localeCompare(b.name)),
				work,
				stocks,
			},
		}
	} catch (error) {
		return {
			notFound: true,
		}
	}
}

export default Home
